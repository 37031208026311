@use "../_vars.scss" as *;

@media screen and (min-width: 320px) {
    .footer {
        font-family: "Stolzl";
        width: 100%;
        height: 4rem;
       
        position: fixed;
        bottom:2%;
        text-align: bottom;
        

        &-content{

            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &__rights{
                position: relative;
                display: flex;
                flex-direction: row;
                margin-right: 5vw;
            }

            &__link {
                margin-left: 5vw;
                color: $Navy;
                text-decoration: none;
                font-size: min(1.25rem,2vw);
                font-style: italic;
                font-weight: bold;
                &:visited{
                    color: $Navy;
                }
                
            }
        }

        &_right{
            position: relative;
            text-align: center;
            width: 10vw;
        }
    }
}

@media screen and (min-width: 768px) {
    .footer{

        
        
       // padding-left: 2rem;
        &-content{
            max-width: 100%;
            &__link{
                &:hover{
                    color:$Navy;
                }
            }
            &__rights{
            margin-right: 5vw;
        }
        }
    }
}