@use "../_vars.scss" as *;
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;700&family=Kanit:ital,wght@1,300&display=swap');

@media screen and (min-width: 320px) {

    @keyframes slideInLeft {
        0%{position: absolute; right: -30vw;}
        100%{position: absolute; right: 0;}
    }

.navbar {
    color: $Navy;
    background: none;
    font-family: "Stolzl";
    font-style: italic;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 2rem;
    padding-left: 5vw;
    padding-right: 5vw;
    font-size: min(1.25rem,2vw);
    font-weight: bold;
    position: fixed;
    top: 0;
    animation-delay: 2s;
    z-index: 999;

    &__link{
        position: relative;

        text-decoration: none;
        font-style: italic;
        font-weight: bold;
        text-align: center;
        //position: absolute;
        right: 10vw;
        //animation: slideInLeft 0.2s ease forwards;
        animation-delay: 1s;
        &-snap{
            // position: absolute;
            // left:200%;
            &:visited {
            color: $Navy;
        }
        }
        
        &:visited {
    
        }
        
        &:hover{
            color: $Navy;
        }
    }

    &__title{
        font-family: "Kanit";
    }
}

}

@media screen and (min-width: 768px) {

    .navbar{

        width: 100%;
        &__link-snap{
            //left: 40%;
        }
    }
}

@media screen and (min-width: 1024px) {

    .navbar{
        &__link-snap{
            //left: 0%;
        }
    }
}