@use "../_vars.scss" as *;
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;700&family=Kanit:ital,wght@1,300&display=swap');

@media screen and (min-width: 320px) {

    .shop{
        height:50vh;

        &__footer{
            position: fixed;
            bottom: 0;
            width: 100vw;
            display: flex;
            justify-content: center;
            color:white;
        }
    }

}

@media screen and (min-width: 768px) {

}

@media screen and (min-width: 1024px) {
    

    
}