@use "../_vars.scss" as *;
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;700&family=Kanit:ital,wght@1,300&display=swap');

@media screen and (min-width: 320px) {

    .product{

        max-width: 100vw;
        max-height: 25vh;
        color: $OffWhite;
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        font-size: min(1.25rem,2vw);

        &__wrapper-link{
            max-width: 100%;
            display: contents;
        }

        &__main{
            max-width: 80%;
            max-height: 80%;
        }

        &__img{
            max-width: 80%;
            margin-left: 5rem;
        }

        &__text{
            display: flex;
            flex-direction: row;
            justify-content: left;
            color: $OffWhite;
            gap: 2rem;
        }

        &__name{

        }

        &__buy{
            transition: 0.5s forward ease-in;
            &:hover{
                background: $OffWhite;
                color: $Navy;
            }
        }

    }

}

@media screen and (min-width: 768px) {
    .product{
        flex-direction: column; 

        &__img{
            
        }
    }
}

@media screen and (min-width: 1024px) {
    .product{

        max-width: 100vw;
        margin-left: 10vw;
        margin-top: 8rem;
        flex-direction: row;
        justify-content: space-between;
        max-height: 50vh;

        &__wrapper-link{
            display: contents;
        }

        &__main{
            max-width: 100%;
        }

        &__img{
            max-width: 100%;
            max-height: 100%;
            margin-left: 0  ;
        }

        &__video{
            max-width: 70%;
            max-height: 70%;
        }

        &__text{
        }

        &__name{

        }

        &__buy{
            
        }

    }
}