@use "../_vars.scss" as *;


@media screen and (min-width: 320px) {
@keyframes rotate {
    0%{transform: rotate(180deg) rotate3d(0,1,0,0);}
    50%{transform: rotate(180deg) rotate3d(0,1,1,180deg);}
    100%{transform: rotate(180deg) rotate3d(0,1,1,360deg);}
}
.post{
        
        max-width: 80vw;
        max-height: 80%;
        padding: 1rem;
        margin-top:1vh;
        margin-bottom: 1rem;
        text-align: left;
        pointer-events: all;
        animation: dropdown 0.5s ease forwards;
        
        
        &-snap{
            scroll-snap-align: start;
        }

        &-bg{
            content: "";
            position: absolute;
            filter: blur(10px);
            width: 100%;
            height: 30vh;
            z-index: -99;
            background-size: cover;
        }

        &__vid{
            display: grid;
            max-width: 100%;
            
            border: none;
            
            &-landscape{
                aspect-ratio: 16/9;
                margin-bottom: 1rem;
                margin-top: 1rem;
                height: 100%;
                width: 90%;
                align-items: center;
                justify-content: center;
            }
            &-portrait{
                aspect-ratio: 9/16;
                height: 40vh;
                width: 50%;
                align-items: start;
                justify-content: start;
            }
            
        }

        &__link{
            text-decoration: none;
            color: $OffWhite;
        }

        &__fieldset{
            border-top: 2px solid $OffWhite;
            border-bottom: none;
            border-left: none;
            border-right: none;
        }

        &__client {
            color:$OffWhite;
            margin: 0;
            font-size: min(1.25rem, 2vw);
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
        &__title{
            color: $OffWhite;
            margin: 0;
        }

        &__subtext{
            color: $OffWhite;
            margin: 0;
        }

        &__body{
            color: $OffWhite;
            text-transform: uppercase;
            font-size: min(1.25rem, 2vw);
        }
        
        &__image{
            max-height: 40vh;
            max-width: 100%;
            
            transform: translateZ(100px);
        }

        &__stack{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            max-width: 100%;
        }

        &__stackImage{
            max-width: 50%;
        }
    }
}

@media screen and (min-width: 768px) {
    .post{
            width: 80vw;   
            padding-left: 0;
            //display: grid;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            align-items: start;
            gap: 1rem;
            grid-template-columns: 0.15fr 1.8fr;
            grid-template-rows: 1fr 0.1fr 0.1fr 0.1fr 0.1fr;
            
            &__vid{
                grid-column: 2/4;
                grid-row: 1/2;
                width: 100%;
                &-portrait{
                    height: 30vh;
                    width: 50%;
                }
            }

            &__link{
                min-height: 0;
                grid-column: 2;
            }

            &__client {
                //display: inline-block;
                font-size: min(1.25rem, 4vw);
                font-style: italic;
                text-align:left;
                line-height: 4rem;
                grid-column: 1;
                grid-row: 1;
                height: -90%;
                //writing-mode: vertical-rl;
                //vertical-align: text-bottom;
                display: flex;
                align-items: start;
                //transform: rotate(180deg);
            }
            &__title{
                grid-column: 2;
                grid-row: 3;
                margin: 0;
            }

            &__subtext{
                grid-column: 2;
                grid-row: 2;
                margin: 0;
            }

            &__body{
                grid-row: 4;
                grid-column: 2;
            }
            
            &__image{
                padding: 1rem;
                max-height: 40vh;
                grid-column: 2/4;
                grid-row: 1/2;
            }

            
        }
}

@media screen and (min-width: 1024px) {

    .post{
            font-size: 1.2rem;

            &__vid{
                &-landscape{
                    width: 70%;
                }
                &-portrait{
                    height: 60vh;
                    width: 50%;
                }
                
                grid-column: 2;
            }

            &__image{

                max-height: 50vh;
            }

            &__stack {
                width: 60%;
            }

            &__stackImage {
                max-width: 50%;
            }
        }
    }
