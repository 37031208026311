@use "../_vars.scss" as *;
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;700&family=Kanit:ital,wght@1,300&display=swap');


        .grid-posts-container {
            $color: $OffWhite;
            margin: 8rem;
        }

        .grid-post-wrapper {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-auto-flow: dense;
        }