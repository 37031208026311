@use "../_vars.scss" as *;

.loading{
    
    font-size: 4rem;
    width: auto;
    line-height: 4rem;
    letter-spacing: 1rem;
    font-style: italic;
     background: linear-gradient( transparent 0%, $OffWhite 50%), url(../../Assets/grain.svg) ;
    background-size: cover;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-clip-path: url(../../Assets/grain.svg);
    clip-path: url(../../Assets/grain.svg);
    filter: contrast(170%) brightness(1000%) saturate(2000%);
    
    &__container {
        display: flex;
        flex-direction: column;
    }
}