@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;700&family=Kanit:ital,wght@1,300&display=swap");
@font-face {
    font-family: 'Stolzl';
    src: url('./Assets/stolzl_font/Stolzl-Light.eot');
    src: local('Stolzl Light'), local('Stolzl-Light'),
        url('./Assets/stolzl_font/Stolzl-Light.eot?#iefix') format('embedded-opentype'),
        url('./Assets/stolzl_font/Stolzl-Light.woff2') format('woff2'),
        url('./Assets/stolzl_font/Stolzl-Light.woff') format('woff'),
        url('./Assets/stolzl_font/Stolzl-Light.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

body {
  margin: 0;
  font-family: "Stolzl", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
